import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { List, ListItem } from '@material-ui/core'
import SideNavItem from '../SideNavItem'
import useStyles from './styles'

const SideNav = () => {
  const classes = useStyles()

  const query = useStaticQuery(graphql`
  {
    allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "main"}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            nav {
              title
              url
              subItems {
                title
                url
              }
            }
          }
        }
      }
    }
  }
  `)

  const navItems = query.allFile.nodes[0].childMarkdownRemark.frontmatter.nav
  return (
    <List className={classes.list}>
      {navItems.map((item) =>
        <ListItem button key={item.title} className={classes.listItem}>
          <SideNavItem item={item} />
        </ListItem>
      )}
    </List>
  )
}

export default SideNav