import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return ({
    wrapper: {
      height: '100%',
      width: '100%',
      overflowY: 'scroll'
    },
    appBG: {
      height: '100%',
      overflow: 'hidden',
    },
    mainContainer: {
      paddingTop: '5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
    main: {
      paddingTop: '3rem',
      paddingBottom: '3rem',
      paddingLeft: '0rem',
      paddingRight: '0rem',
      marginBottom: '4rem',
    },
    logo: {
      backgroundColor: theme.palette.background.offWhite,
      padding: '2rem',
      '& h3': {
        marginBottom: '3rem',
      }
    },
    content: {
      padding: '2rem',
      backgroundColor: theme.palette.background.main,
    },
    drawer: {
      width: '250px'
    }
  })
})

export default useStyles