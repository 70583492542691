import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: '0 !important',
    borderBottom: '1px solid #cccccc'
  },
  list: {
    width: '250px'
  }
}));

export default useStyles