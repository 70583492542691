import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  nav: {
    backgroundColor: theme.palette.background.menu,
    '& p': {
      textTransform: 'uppercase',
    }
  },
}));

export default useStyles