import React from "react"
import { Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem } from '@material-ui/core'
import { Link } from "gatsby"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './styles'

const SideNavItem = ({ item }) => {
  const classes = useStyles()

  return <>
    {item.url
      ? (
        <Link to={item.url} className={classes.link}>
          <Typography variant="body1" component="p" color='primary' display='inline'>
            {item.title}
          </Typography>
        </Link>
      )
      : <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={item.title}
          className={classes.accordionSummary}
        >
          <Typography variant="body1" component="p" color='primary' display='inline'>
            {item.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <List className={classes.list}>
            {item.subItems.map((subItem) =>
              <ListItem button key={subItem.title} className={classes.listItem}>
                <Link to={subItem.url} className={classes.link}>
                  <Typography variant="body1" component="p" color='primary' display='inline'>
                    {subItem.title}
                  </Typography>
                </Link>
              </ListItem>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    }
  </>
}

export default SideNavItem