import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid } from '@material-ui/core'
import NavItem from '../NavItem'
import useStyles from './styles'

const Nav = () => {
  const query = useStaticQuery(graphql`
  {
    allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "main"}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            nav {
              title
              url
              subItems {
                title
                url
              }
            }
          }
        }
      }
    }
  }
  `)

  const navItems = query.allFile.nodes[0].childMarkdownRemark.frontmatter.nav
  const classes = useStyles()


  return (
    <Grid container spacing={4} className={classes.nav}>
      {navItems.map((item) => <NavItem item={item} key={item.title} />)}
    </Grid>
  )
}

export default Nav