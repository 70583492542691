import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { AppBar, Grid, Toolbar, Typography, Hidden } from '@material-ui/core'
import Nav from '../Nav'
import useStyles from './styles'

import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'


const Header = ({ siteTitle, cat, openNav }) => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
  {
    allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "home"}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            logo {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }
          }
        }
      }
    }
  }
`)

  const logo = query.allFile.nodes[0].childMarkdownRemark.frontmatter.logo.childImageSharp.fluid

  return (
    <AppBar position="fixed" className={classes.header}>
      <Toolbar>
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Hidden mdDown>
            <Grid item>
              <Link to="/" className={classes.mainTitle} activeClassName={classes.activeLink}>
                <Typography variant="h3" component="h1" color='primary' className={classes.siteTitle}>
                  {siteTitle}
                </Typography>
                <Typography variant="h5" component="h2" color='primary' className={classes.siteTitle}>
                  {cat}
                </Typography>
              </Link>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid item>
              <Link to="/" className={classes.mainTitle} activeClassName={classes.activeLink}>
                <img src={logo.originalImg} alt='logo' />
              </Link>
            </Grid>
          </Hidden>
          <Grid item>
            <Hidden mdDown>
              <Nav />
            </Hidden>
            <Hidden lgUp>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={openNav}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
