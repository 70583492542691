import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.light,
    padding: '1rem 2rem',
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    textAlign: 'right'
  },
  link: {
    color: '#ffffff',
    marginRight: '0.5rem',
    textDecoration: 'none',
  }
}));

export default useStyles