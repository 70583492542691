import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography, Hidden } from '@material-ui/core'
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Header from "../Header/header"
import Footer from '../Footer'
import Container from '@material-ui/core/Container'
import useStyles from './styles'
import SideNav from '../SideNav'
import Drawer from '@material-ui/core/Drawer'


const Layout = ({ children, isHome }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const data = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        title
        cat
        subTitle
      }
    }
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "main"}}}) {
      nodes {
        frontmatter {
          backGround {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "home"}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            logo {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`)

  const bgImage = data.allMarkdownRemark.nodes[0].frontmatter.backGround.childImageSharp.fluid
  const title = data.site.siteMetadata.title
  const cat = data.site.siteMetadata.cat
  const logo = data.allFile.nodes[0].childMarkdownRemark.frontmatter.logo.childImageSharp.fluid
  const subTitle = data.site.siteMetadata.subTitle

  return (
    <BackgroundImage fluid={bgImage} className={classes.appBG}>
      <div className={classes.wrapper}>
        <Header siteTitle={title} cat={cat} openNav={handleDrawerOpen} />
        <main className={classes.main}>
          <Container className={classes.mainContainer} >
            <Grid container spacing={4} justify="center">
              <Hidden mdDown>
                <Grid item xs={12} lg={4}>
                  <div className={classes.logo} >
                    <Img fluid={logo} />
                    <Typography variant="h4" component="h3" color='primary' className={classes.siteTitle} style={{ textAlign: 'center', marginTop: '1rem' }}>
                      {subTitle}
                    </Typography>
                  </div>
                </Grid>
              </Hidden>
              <Grid item xs={12} lg={8}>
                <div className={classes.content}>
                  {children}
                </div>
              </Grid>
            </Grid>
          </Container>
        </main>
        <Footer />
      </div>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
      >
        <SideNav />
      </Drawer>
    </BackgroundImage>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
