import { Link } from "gatsby"
import React from "react"
import { Grid } from '@material-ui/core'
import useStyles from './styles'
import CookieConsent from "react-cookie-consent"

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Grid container >
        <CookieConsent
          location="bottom"
          buttonText="Akzeptieren"
          declineButtonText="Ablehnen"
          buttonStyle={{background:'#3a9151', color:'#ffffff'}}
          enableDeclineButton
          onAccept={ () =>{
            window.location.reload()
            }
          }
          onDecline={ () =>{
            window.location.reload()
            }
          }
          flipButtons
          sameSite='strict'
          overlay={false}
          style={{ background: '#ffffffe6', padding: '2rem 0' }}
          contentStyle={{ color: '#000000', textAlign: 'center',width:'300px', fontSize:'1.5rem', lineHeight:'1.5rem'}}
          cookieName="gatsby-gdpr-google-analytics">
          <p> Wir benutzen Cookies, wie Google Analytics, um die Nutzung der Website zu messen und diese dadurch zu verbessern. Klicken Sie bitte auf den "Akzeptieren" oder "Ablehnen" Button, um fortzufahren. Mehr dazu erfahren Sie unter <Link to='/datasecurity'>Datenschutz</Link>. Vielen Dank für Ihre Unterstützung!</p>
        </CookieConsent>
        <Grid item xs={12} >
          <Link to="/impressum" className={classes.link}>
            IMPRESSUM
          </Link>
          <Link to="/datasecurity" className={classes.link}>
            DATENSCHUTZ
          </Link>
        </Grid>
      </Grid>
    </footer>
  )
}
export default Footer

