import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '1rem',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:active': {
        textDecoration: 'none',
      }
    }
  },
  mainTitle: {
    color: theme.palette.primary,
    margin: 0,

    '&:active': {
      color: theme.palette.primary
    },

    '& img': {
      maxWidth: '64px'
    }

  },
  menu: {
    listStyle: 'none',
    padding: '1rem',
    backgroundColor: theme.palette.background.menu,
    margin: 0,
    border: `1px solid ${theme.palette.primary.menu}`
  },
  menuItem: {
    display: 'inline',
    margin: '0 .5rem',

    '& p': {
      color: theme.palette.primary.menu,
    }
  },
  siteTitle: {
    display: 'inline',
    marginRight: '.3rem !important'
  }
}));

export default useStyles