import React from "react"
import { Typography, Menu, MenuItem, Grid } from '@material-ui/core'
import { Link } from "gatsby"
import useStyles from './styles'

const NavItem = ({ item }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return <Grid item className={classes.navItem}>
    {item.url
      ? (
        <Link to={item.url}>
          <Typography variant="body1" component="p" color='primary' display='inline'>
            {item.title}
          </Typography>
        </Link>
      )
      : (
        <Typography
          variant="body1"
          component='p'
          color='primary'
          display='inline'
          aria-controls="menu"
          aria-haspopup="true"
          onClick={item.url ? null : handleClick}
        >
          {item.title}
        </Typography>
      )
    }
    {item.subItems &&
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        className={classes.menu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {item.subItems.map((subItem) =>
          <Link to={subItem.url} key={subItem.title}>
            <MenuItem>
              {subItem.title}
            </MenuItem>
          </Link>
        )}
      </Menu>
    }

  </Grid>
}

export default NavItem