import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: '100%',
    margin: '0 !important',
  },
  accordionSummary: {
    minHeight: '0 !important',
    '& p': {
      margin: 0,
    },
    '& .Mui-expanded': {
      margin: '0 !important',
    }
  },
  accordionDetails: {
    padding: 0,
  },
  list: {
    padding: 0,
    width: '100%'
  },
  listItem: {
    paddingLeft: '2rem',
    paddingTop: 0,
    paddingBottom: 0,
    borderTop: '1px solid #cccccc'
  },
  link: {
    width: '100%',
    padding: '.5rem 1rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    }
  }
}));

export default useStyles