import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  navItem: {
    display: 'inline',
    margin: '0 .5rem',
    '& p': {
      color: theme.palette.primary.menu,
    },
    cursor: 'pointer'
  },
  menu: {
    '& .MuiPaper-root': {
      marginTop: '1.5rem',
      backgroundColor: theme.palette.background.menu,
      // border: `1px solid ${theme.palette.primary.menu}`,
      // borderRadius: 0,
    },
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:active': {
        textDecoration: 'none',
      }
    },
    '& li': {
      color: theme.palette.primary.menu,

    }
  }
}));

export default useStyles